// // Slider Setup //
function sliderSetup() {
    $('.slider-container .slick-initialized').slick('unslick');

    var $sliderContainer = $('.slider-container');

    $sliderContainer.each(function () {
        var $container = $(this),
            $slider = $container.find('.slider'),
            $controls = $container.find('.slider-controls'),
            $dots = $container.find('.slider-dots'),
            centerMode = false,
            dots = false,
            infinite = false,
            fade = false,
            auto = false;

        // Dots //
        if ($container.hasClass('dots')) {
            dots = true;
        }

        // Infinite //
        if ($container.hasClass('infinite')) {
            infinite = true;
        }

        // Fade //
        if ($container.hasClass('fade')) {
            fade = true;
        }

        // Auto //
        if ($container.hasClass('auto')) {
            auto = true;
        }

        // Center Mode //
        if ($container.hasClass('center-mode')) {
            centerMode = true;
        }

        $slider
            .not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: dots,
            infinite: infinite,
            fade: fade,
            autoplay: auto,
            autoplaySpeed: 6000,
            appendDots: $dots,
            appendArrows: $controls,
            centerMode: centerMode,
            prevArrow: '<a class="slick-prev"><span class="icon--angle-left"></span></a>',
            nextArrow: '<a class="slick-next"><span class="icon--angle-right"></span></a>'
        });
    });
} //setup slider -- function end

function testimonialSliderSetup() {
    $('.testimonial-slider .slick-initialized').slick('unslick');

    var $sliderContainer = $('.slider-container');

    $sliderContainer.each(function () {
        var $container = $(this),
            $slider = $container.find('.slider'),
            $controls = $container.find('.slider-controls'),
            $dots = $container.find('.slider-dots'),
            centerMode = false,
            dots = false,
            infinite = false,
            fade = false,
            auto = false;

        // Dots //
        if ($container.hasClass('dots')) {
            dots = true;
        }

        // Infinite //
        if ($container.hasClass('infinite')) {
            infinite = true;
        }

        // Fade //
        if ($container.hasClass('fade')) {
            fade = true;
        }

        // Auto //
        if ($container.hasClass('auto')) {
            auto = true;
        }

        // Center Mode //
        if ($container.hasClass('center-mode')) {
            centerMode = true;
        }

        $slider
            .not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: dots,
            infinite: infinite,
            fade: fade,
            autoplay: auto,
            autoplaySpeed: 6000,
            appendDots: $dots,
            appendArrows: $controls,
            centerMode: centerMode,
            prevArrow: '<a class="slick-prev"><span class="icon--angle-left"></span></a>',
            nextArrow: '<a class="slick-next"><span class="icon--angle-right"></span></a>'
        });
    });
} //setup slider -- function end

// Slider Setup //
function logoSliderSetup() {
    $('.logo-slider .slick-initialized').slick('unslick');

    var $sliderContainer = $('.logo-slider');

    $sliderContainer.each(function () {
        var $container = $(this),
            $slider = $container.find('.slider'),
            $controls = $container.find('.slider-controls'),
            $dots = $container.find('.slider-dots'),
            centerMode = false,
            dots = false,
            infinite = false,
            fade = false,
            auto = false,
            slidesToShow;
        // Dots //
        if ($container.hasClass('dots')) {
            dots = true;
        }

        // Infinite //
        if ($container.hasClass('infinite')) {
            infinite = true;
        }

        // Fade //
        if ($container.hasClass('fade')) {
            fade = true;
        }

        // Auto //
        if ($container.hasClass('auto')) {
            auto = true;
        }

        // Center Mode //
        if ($container.hasClass('center-mode')) {
            centerMode = true;
        }

        // Change slides to show - depending on screen size
        if ($(window).width() > 1200) {
            slidesToShow = 4;
        } else if ($(window).width() > 1024) {
            slidesToShow = 3;
        } else if ($(window).width() > 767) {
            slidesToShow = 2;
        } else if ($(window).width() < 480) {
            slidesToShow = 1;
        }

        $slider
            .not('.slick-initialized').slick({
            centerMode: centerMode,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            dots: dots,
            infinite: infinite,
            fade: fade,
            autoplay: auto,
            autoplaySpeed: 4000,
            appendDots: $dots,
            appendArrows: $controls,
            prevArrow: '<a class="slick-prev"><span class="icon-arrow-left"></span></a>',
            nextArrow: '<a class="slick-next"><span class="icon-arrow-right"></span></a>',
        });
    });
} //setup slider -- function end