$(function() {
    // Vars //
    var $body = $('body');
    var $window = $(window);
    var $header = $('header');

    var $wordArt = '================================================================================================\n' +
                '================================================================================================\n' +
                '\tWebsite Created by:\n' +
                '\t ____  _            _      __          __   _  __    _____            _                 \n' +
                '\t|  _ \\| |          | |     \\ \\        / /  | |/ _|  |  __ \\          (_)                \n' +
                '\t| |_) | | __ _  ___| | __   \\ \\  /\\  / /__ | | |_   | |  | | ___  ___ _  __ _ _ __  ___ \t\n' +
                '\t|  _ <| |/ _` |/ __| |/ /    \\ \\/  \\/ / _ \\| |  _|  | |  | |/ _ \\/ __| |/ _` | \'_ \\/ __|\n' +
                '\t| |_) | | (_| | (__|   <      \\  /\\  / (_) | | |    | |__| |  __/\\__ \\ | (_| | | | \\__ \\\n' +
                '\t|____/|_|\\__,_|\\___|_|\\_\\      \\/  \\/ \\___/|_|_|    |_____/ \\___||___/_|\\__, |_| |_|___/\n' +
                '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t __/ |          \n' +
                '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t|___/           \n' +
                '\n' +
                '\t\t\t\t\t\t\t\t\t\t\t\t\t\tWebsite & Graphic Design Agency\n' +
                '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\n' +
                '================================================================================================';

    // Functions //
    sliderSetup();
    logoSliderSetup();
    // testimonialSliderSetup();
    // basicIDLinks();

    $body
    .on('click', '.js-menu-trigger' , function(e){
        e.preventDefault();
        if($body.hasClass('menu-open')) {
            $body.removeClass('menu-open');
        } else {
            $body.addClass('menu-open');
        }
    })
    .on('click', '.js-accordion-trigger', function(e){
        e.preventDefault();
        var $accordion = $(this).closest('.block-accordion');
        var $accordionContent = $($accordion).find('.block-accordion__content');
        if($accordion.hasClass('open')) {
            $accordion.removeClass('open');
            $accordionContent.slideUp();

        } else {
            $accordion.addClass('open');
            $accordionContent.slideDown();
        }
    })
    ;

    console.log($wordArt);

    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        buttons: [
            "close"
        ],
    });

    // AOS.init({
    //     useClassNames: true,
    //     initClassName: false,
    //     animatedClassName: 'animated',
    // });

    // Resize Functions //
    var resizeThreshold;
    $(window).on('resize', function () {
        if (resizeThreshold) {
            clearTimeout(resizeThreshold);
        }

        resizeThreshold = setTimeout(function () {
            setTimeout(function () {
                // Functions //
                sliderSetup();
                logoSliderSetup();
                // testimonialSliderSetup();
                // $window.trigger('resize');
            }, 100);
        }, 200);
    });
});


